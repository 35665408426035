import React from 'react';
import { Col, Row, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WhiteLogo } from 'assets/icons/WhiteLogo.svg';
import { Link } from 'react-router-dom';

const AuthLayout = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { token } = theme.useToken();
  const { t } = useTranslation();
  return (
    <Row
      align={'stretch'}
      wrap={true}
      style={{
        minHeight: '100vh',
        backgroundColor: token.colorBgLayout,
      }}
    >
      <Col
        xs={0}
        sm={0}
        md={0}
        lg={8}
        style={{
          padding: '40px 0px 40px 40px',
          backgroundColor: token.colorPrimary,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Link to={'/'} style={{ height: 90 }}>
            <WhiteLogo />
          </Link>
          <Typography.Title
            style={{
              color: token.colorPrimaryText,
            }}
            level={1}
          >
            {t('auth.hero')}
          </Typography.Title>
          <div />
        </div>
      </Col>
      <Col flex={1}>{children}</Col>
    </Row>
  );
};

export default React.memo(AuthLayout);
