let prefixer: string | undefined;

const hostname = window.location.hostname;
const port = window.location.port;

switch (hostname) {
  case process.env.REACT_APP_STAGING_HOST:
    prefixer = process.env.REACT_APP_STAGING_API_URL;
    break;
  case process.env.REACT_APP_DEV_HOST:
    prefixer = process.env.REACT_APP_DEV_API_URL;
    break;
  case process.env.REACT_APP_PROD_HOST:
    prefixer = process.env.REACT_APP_PROD_API_URL;
    break;
  default:
    if (port === '3000') prefixer = process.env.REACT_APP_LOCAL_API_URL;
    else prefixer = process.env.REACT_APP_DEV_API_URL;
}

export const AuthUrls = {
  PASSWORD_LOGIN: `${prefixer}/auth/local`,
  RESET_PASSWORD_INIT: `${prefixer}/user/forgot-password`,
  RESET_PASSWORD: `${prefixer}/user/reset-password`,
  CREATE_PASSWORD: `${prefixer}/user/create-password`,
  REFRESH_TOKEN: `${prefixer}/auth/refresh-token`,
};

export const UserUrls = {
  GET_CONNECTED_INFO: `${prefixer}/users/me`,
  GET_ACCOUNT_INFO: `${prefixer}/user/infos`,
};
