import { Flex, MenuProps } from 'antd';
import React from 'react';

import { ReactComponent as HomeIcon } from 'assets/icons/Home.svg';

export type MenuItem = Required<MenuProps>['items'][number];

export const navigationMenus = [
  {
    key: '/',
    label: 'Accueil',
    icon: <HomeIcon />,
  },
];
const navigationConfig = (onClick?: (e: any) => void): MenuItem[] =>
  navigationMenus.map((menu) => {
    return {
      ...menu,
      onClick,
      icon: (
        <Flex style={{ width: 24 }} justify={'center'}>
          {menu.icon}
        </Flex>
      ),
    };
  });

export default navigationConfig;
