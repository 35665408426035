import { ConfigProviderProps } from 'antd';
import frFR from 'antd/locale/fr_FR';
import 'theme/overrides.scss';

const appTheme: ConfigProviderProps = {
  locale: frFR,
  button: {
    className: 'button-override',
  },
  input: {
    className: 'input-override',
  },
  textArea: {
    className: 'textarea-override',
  },
  theme: {
    cssVar: { key: 'app' },
    token: {
      fontFamily: 'Fieldwork, sans-serif',
      colorPrimary: '#0070BA',
      colorHighlight: '#0070BA',
      colorText: '#3A3A3A',
      colorSuccess: '#20A76A',
      colorSuccessBg: '#EBF4EE',
      colorSuccessBorder: '#EBF4EE',
      colorPrimaryText: '#FFFFFF',
      colorBgLayout: '#F7F5F4',
      colorBgContainer: '#FFFFFF',
      colorTextDisabled: '#C6C2BE',
      borderRadius: 4,
      fontSizeHeading1: 48,
      lineHeightHeading1: 1.16,
      fontSizeHeading2: 28,
      fontSizeHeading4: 24,
      fontSizeHeading5: 20,
      lineHeightHeading5: 1,
      lineHeight: 1.57,
      boxShadow:
        '0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02)',
    },
  },
};

export default appTheme;
